.navbar {
    background-color: #111;
}

.circle {
    border-radius: 50%;
    background-color: #ffffff;
    height: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 40px;
    display: flex;
    cursor: pointer;
    z-index: 30;
    position: fixed;
}

.navbar-brand {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 18px;
    padding-bottom: 18px;
}

.logo-navbar {
    min-width: 90px;
    width: 100%;
}

.menu-icon {
    color: #ffffff;
}

.dropdown-m {
    max-width: 300px;
    font-size: 14px;
}

.ai-menu {
    background-color: transparent !important;
    color: #ffffff;
    padding: 0.5rem 1.25rem;
}

.menu-item {
    color: #ffffff;
    padding: 0.5rem 1.25rem;
    text-decoration: none;
}

.ai-menu:not(.collapsed) {
    color: #ffffff;
    background-color: transparent;
    font-weight: 500;
    box-shadow: none;
}

.ai-menu:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.menu-item:active,
.menu-item:hover,
.menu-item:focus {
    color: #ffffff !important;
}

.ai-menu:focus {
    box-shadow: none !important;
}

.a-item-n {
    background-color: #111 !important;
    color: #ffffff;
}


.btn-logout {
    color: #ffffff;
    padding: 0.5rem 1.25rem;
    display: none;
}

.btn-logout:hover {
    background-color: #111;
    border: 1px solid #111;
    color: #ffffff;
}


.btn-logout:active,
.btn-logout:focus {
    border: 1px solid #111 !important;
    outline: none !important;
    box-shadow: none;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}


.accordion-body .menu-item {
    border-left: 1px solid #ffffff;
}


.acc-menu {
    padding: 1rem 1.6rem;
}

.btn-logout,
.menu-item {
    font-size: 15px;
}

@media (max-width: 1399.98px) {
    .circle-lg {
        display: flex;
    }
}

@media (max-width: 991.98px) {
    .circle-lg {
        display: none !important;
    }


    .btn-logout {
        display: block !important;
    }

}