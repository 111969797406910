.mdt-container {
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 600px;
    min-width: 260px;
}

.accordion-button,
.accordion-header,
.accordion-item {
    border-radius: 12px !important;
}

.accordion-button:not(.collapsed) {
    color: #0066ff;
    background-color: transparent;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.accordion-button {
    font-size: 15px;
}

.accordion-button:focus {
    z-index: 3;
    border-color: transparent !important;
    outline: none;

}

.accordion-item {
    background-color: #fff;
    box-shadow: 0px 4px 13px rgb(0 0 0 / 15%);
    border: 1px solid transparent;
}

.label-tag {
    font-size: 14.5px;
    margin-bottom: 5px;
}

.label-bold {
    font-weight: 600;
}

.form-select {
    font-size: 14px;
}

.btn-save {
    font-family: 'HeadingProWide';
    font-size: 14.5px;
    width: 100%;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
}

.main-error {
    color: #E5332A;
    font-size: 12px;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 2%;
    font-weight: 500;
}