.bg-left {
    background: linear-gradient(rgba(0, 0, 0, 0.40),
            rgba(0, 0, 0, 0.40)), url('../../assets/img/bg-left.jpg');
    height: 100vh;
    border-color: rgba(0, 0, 0, 1);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.left-text {
    font-family: "HeadingProWide" !important;
    color: #FFFFFF;
    font-size: 45px;
    text-align: left;
    line-height: 45px;
    margin-bottom: 20px;
}

.l-txt {
    text-align: left;
    color: #FFFFFF;
    font-weight: 200;
    font-size: 11px;
    line-height: 13px;
    opacity: 0.6;
}

.logo-dark {
    width: 100%;
    margin-bottom: 8%;
}

.lg-error {
    color: #E5332A;
    font-size: 12px;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 7%;
    font-weight: 500;
}

.login-dv {
    width: 60%;
    height: auto;
    margin: 10px auto;
}

.btn-login {
    margin-top: 4.5%;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin-bottom: 0px;
    background-color: #111111;
    line-height: 21.6px;
    color: #FFFFFF;
    font-family: "HeadingProWide" !important;
}

.btn-login:hover,
.btn-login:active,
.btn-login:focus {
    background-color: #111 !important;
    color: #FFF !important;
}

.o-pwd {
    color: #111111;
}

.o-pwd:active,
.o-pwd:focus,
.o-pwd:hover {
    color: #111111;
    font-weight: 600;
}

.input-group-text-rec {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 2px solid #111111;
    border-right: none;
    border-radius: 40px;
}

.form-control-rec.is-invalid,
.input-group-text-rec.is-invalid,
.input-group-text-lg.is-invalid,
.input-group-text-lg:invalid,
.was-validated,
.form-control-rec:invalid,
.was-validated .input-group-text-rec:invalid {
    border-color: #E5332A;
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.form-control-rec:-webkit-autofill,
.form-control-rec:-webkit-autofill:hover,
.form-control-rec:-webkit-autofill:focus,
.form-control-rec:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

.input-group-text-rec {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 2px solid #111111;
    border-right: none;
    border-radius: 40px;
}

.form-control-rec {
    display: block;
    border-left: none !important;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #111111;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 40px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control-rec::-moz-placeholder {
    color: #111111;
    opacity: 1;
}

.form-control-rec::-webkit-input-placeholder {
    color: #111111;
    opacity: 1;
}

.input-group>.form-control-rec,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}

.input-group-text-lg {
    border: 2px solid #111;
    border-left: none;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.eye-pwd {
    color: #d8d8d8;
}

.eye-pwd:hover,
.eye-pwd:active,
.eye-pwd:focus {
    color: #a7a7a7;
}

.website,
.sm {
    color: #111111;
    font-weight: 600;
}

.website {
    cursor: pointer;
    text-decoration: none;
}

.form-control-rec:active,
.form-control-rec:focus {
    outline: none !important;
}

.website:hover,
.website:focus,
.website:active {
    color: #0066FF;
    text-decoration: underline;
}

.icon-sm {
    color: #111111;
    font-size: 22px;
}

.icon-sm:hover,
.icon-sm:active,
.icon-sm:focus {
    color: #0066FF;
    transform: scale(1.2);
}

.login {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
}

.footer-a {
    padding-top: 1.5rem !important;
    padding-bottom: 2rem;
}

.cont-lg {
    height: 80%;
    padding-top: 20%;
}

@media (max-width: 991.98px) {
    .ct-login {
        height: 100vh;
    }

    .r-footer {
        display: block !important;
    }
}

@media (max-width: 638px) {
    .cont-lg {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media (max-width: 580px) {
    .login-dv {
        width: auto;
    }
}

@media (max-width: 452px) {

    .footer-a {
        font-size: 12px;
    }

    .footer-mobile {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .o-pwd {
        font-size: 14px;
    }

    .form-control-rec {
        font-size: 14px;
    }

    .btn-login {
        font-size: 14.5px;
    }
}


@media (max-width: 375px) {
    .form-control-rec {
        font-size: 13px;
    }
}


.btn-login:disabled {
    border: 1px solid #C4C4C4;
    background-color: #C4C4C4 !important;
    color: #111 !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 21.6px;
}


@media (max-height: 600px) {
    .footer-a {
        padding-top: 0rem !important;
    }

    .cont-lg {
        padding-top: 0%;
    }
}

.r-login {
    height: 90%;
}

.r-footer {
    height: 10%;
    display: none;
}

.footer-mobile {
    background-color: #111;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    height: 100%;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footerm-txt {
    color: #FFFFFF;
    font-size: 9px;
    margin-bottom: 5px;
    line-height: 8px;
    text-align: center;
}