.Toastify__toast {
    border-radius: 25px !important;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 14px;
}

.Toastify__toast {
    padding: 20px !important;
    color: #807e7e !important;
}

@media (max-width: 540px) {
    .Toastify__toast-body {
        font-size: 13px;
    }
}