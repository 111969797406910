.mhdr {
    border: none !important;
    padding-top: 4%;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 0%;
}

.container-info {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    position: relative;
}


.card-pm {
    width: 15rem;
    margin-right: 1.5%;
    margin-bottom: 7px;
}


.btn-disabled {
    opacity: 0.4;
    pointer-events: none;
}

.exp-btn {
    color: #7a7a7a;
    text-decoration: underline;
    font-size: 14px;
}

.exp-btn:hover {
    color: #7a7a7a;
}

.collapse-upd {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.exp-btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.upd-det {
    font-weight: 600 !important;
    font-size: 13px;
}

.modal-error {
    color: #f55b5d;
    font-weight: 700;
    margin-top: 15px !important;
    font-size: 40px;
    margin-bottom: 15px !important;
}

.modal-success {
    color: #0066ff;
    font-weight: 700;
    margin-top: 15px !important;
    font-size: 40px;
    margin-bottom: 15px !important;
}

.modal-error-txt {
    font-weight: 700;
    color: #f55b5d;
}

.modal-success-txt {
    font-weight: 700;
    color: #0066ff;
    font-size: 20px;
}

.oportunidades_t {
    font-size: 22px;
    line-height: 140%;
    font-weight: 600;
}

.modal-dialog {
    width: 100%;
    max-width: 650px;
    height: auto;
}

.close-modal:active,
.close-modal:focus {
    box-shadow: none !important;
    outline: none !important;
}

.modal1 {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: auto;
}

.modal-icon {
    font-size: 60px;
}



.btn-primary:disabled {
    border: 1px solid #c4c4c4;
    background-color: #c4c4c4 !important;
    color: #212529;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 21.6px;
}

.modal-text-1 {
    color: #111111;
    font-size: 13px;
    line-height: 140%;
    width: 100%;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 600;
    padding: 0.375rem 0.95rem;
}

.mbdy {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 3%;
    padding-top: 0px !important;
}

.m-button-wrapper {
    width: 79%;
    margin-left: auto;
    margin-right: auto;
}

.text-label {
    font-size: 13px;
    line-height: 23px;
}

@media (max-width: 426px) {
    .modal-dialog {
        min-width: 50%;
        height: auto;
    }

    .mbdy {
        padding-right: 20px;
        padding-left: 20px;
    }

    .modal-text {
        font-size: 28px;
        line-height: 30px;
    }
}

@media (max-width: 363px) {
    .modal-text {
        font-size: 24px;
        line-height: 28px;
    }
}