.mhdr2 {
    border: none !important;
    padding-top: 6%;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 6%;
}

.close-modal {
    background: url("../../../assets/svg/closeicon.svg");
    background-size: cover;
    opacity: 1;
    width: 18px !important;
    height: 18px !important;
    cursor: pointer;
}

.modal-header .btn-close {
    padding: 0px !important;
    margin: -0.5rem -0.5rem -0.5rem auto;
    border-radius: 0px !important;
}

.modal2 {
    border-radius: 40px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border: none !important;
    width: auto;
}

.modal-icon {
    font-size: 60px;
}

.modal-icon-success {
    color: #0066FF !important;
}

.modal-icon-error {
    color: #E5332A;
}

.modal-text-m2 {
    color: #111111;
    font-weight: bold;
    width: 100%;
    margin-top: 2%;
    font-size: 18px;
    line-height: 140%;
    margin-left: auto;
    margin-right: auto;
}

.mbdy2 {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 9%;
}

.m-button-wrapper {
    width: 79%;
    margin-left: auto;
    margin-right: auto;
}


@media (max-width: 426px) {

    .modal-dialog {
        min-width: 50%;
        height: auto;
    }

    .mbdy2 {
        padding-right: 20px;
        padding-left: 20px;
    }

    .modal-text {
        font-size: 28px;
        line-height: 30px;
    }
}

@media (max-width: 363px) {

    .modal-text {
        font-size: 24px;
        line-height: 28px;
    }
}