.form-input {
    display: block;
    width: 100%;
    padding: .47rem .95rem;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #111;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #111;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.form-input:active, .form-input:focus {
    border: 1px solid #111 !important;
    outline: none !important;
}


