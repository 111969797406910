.icon-error {
    font-size: 30px;
}

.error-txt, .error-title, .icon-error {
    color: #E5332A;
}

.error-txt {
    font-size: 14px;
    font-weight: 500;
}

.page-title {
    margin-top: 10px;
    color: #111;
    margin-bottom: 0px;
}

.page-txt {
    color: #111;
}

.lock-icon {
    font-size: 45px;
    color: #0066ff;
    padding-bottom: 10px;
}
